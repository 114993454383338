//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";

export default {
  name: "SectionPhotobank",
  components: {
    Gallery: () => import("@/components/common/Gallery"),
    Section: () => import("@/components/common/Section/index"),
  },
  props: {
    background: {
      type: String,
      default: () => 'var(--dark-color)'
    },
    disableMarginTop: {
      type: Boolean,
      default: () => false
    },
    disableMarginBottom: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    ...mapGetters({
      photos: "photos/photos",
      pending: "photos/pending"
    }),
    show() {
      return this?.photos?.length > 0 && !this?.pending
    }
  },
  mounted() {
    this.$store.dispatch("photos/photos", {
      clear: true,
      params: {
        resolution: "micro",
        lang: this.$i18n.locale
      }
    })
  }
};
